import clsx from 'clsx'
import React, { ComponentProps, ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import CheckIcon from '@heroicons/react/24/outline/CheckIcon'
import ClipboardIcon from '@heroicons/react/24/outline/ClipboardIcon'
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon'
import { useCronStringState } from './useCronStringState'
import { copyText, sleep } from './utils'

export interface CronInputProps extends Omit<ComponentProps<'div'>, 'onChange'> {
  value: string
  onChange(cronValue: string): void
  onValidityChange?(isValid: boolean): void
  noCopy?: boolean
  noFriendlyText?: boolean
  noErrorMessage?: boolean
  noValidate?: boolean
  label?: ReactNode
  hideHelp?: boolean
}

const miniLabelClasses =
  'text-[10px]/[1] text-gray-400 text-center px-1 max-w-min justify-self-center flex items-end mb-0.5 transition-all duration-500'
const miniLabelErrorClasses = 'text-red-300'

export const CronInput = ({
  id: givenId,
  className = '',
  value,
  onChange,
  onValidityChange,
  label = '',
  noCopy = false,
  noFriendlyText = false,
  noErrorMessage = false,
  noValidate = false,
  hideHelp = false,
  ...props
}: CronInputProps) => {
  const id = useMemo(() => givenId || `cron-input-${Math.floor(Math.random() * 1000)}`, [givenId])
  const [valid, setValid] = useState(true)
  const prevValidity = useRef(valid)

  if (prevValidity.current !== valid) {
    prevValidity.current = valid
    onValidityChange?.(valid)
  }

  const {
    seconds,
    minutes,
    hours,
    monthDays,
    months,
    weekDays,
    setSeconds,
    setMinutes,
    setHours,
    setMonthDays,
    setMonths,
    setWeekdays,
    friendlyText,
    secondsErrors,
    minutesErrors,
    hoursErrors,
    monthDaysErrors,
    monthsErrors,
    weekDaysErrors,
  } = useCronStringState(value, onChange)

  useEffect(() => {
    if (
      secondsErrors.length ||
      minutesErrors.length ||
      hoursErrors.length ||
      monthDaysErrors.length ||
      monthsErrors.length ||
      weekDaysErrors.length ||
      !minutes ||
      !hours ||
      !monthDays ||
      !months ||
      !weekDays
    ) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [
    secondsErrors.length,
    minutesErrors.length,
    hoursErrors.length,
    monthDaysErrors.length,
    monthsErrors.length,
    weekDaysErrors.length,
    minutes,
    hours,
    monthDays,
    months,
    weekDays,
  ])

  const [copied, setCopied] = useState(false)

  const allErrors = noValidate
    ? []
    : [...secondsErrors, ...minutesErrors, ...hoursErrors, ...monthDaysErrors, ...monthsErrors, ...weekDaysErrors]

  return (
    <div className={`${className} flex max-w-full flex-col`} {...props}>
      {label && (
        <label className="mb-1 flex items-center dark:text-white">
          {label}
          {!hideHelp && (
            <button
              type="button"
              role="link"
              className="ml-2 rounded-full border border-transparent hover:border-primary-400 hover:text-primary-700 focus:border-primary-400 focus:text-primary-700 focus:outline-none"
              onClick={() => window.open('https://crontab.guru/', '_blank', 'noopener=true; noreferrer=true')}
            >
              <InformationCircleIcon className="h-5 w-5 focus:outline-none" aria-hidden />
            </button>
          )}
        </label>
      )}
      <div
        className="group -mx-0.5 grid gap-y-px overflow-x-auto overflow-y-visible px-0.5 pb-0.5"
        style={{ gridTemplateColumns: `repeat(${noCopy ? 6 : 7}, auto)` }}
      >
        <span className={clsx(miniLabelClasses, { [miniLabelErrorClasses]: !noValidate && secondsErrors.length })}>
          seconds
        </span>
        <span className={clsx(miniLabelClasses, { [miniLabelErrorClasses]: !noValidate && minutesErrors.length })}>
          minutes
        </span>
        <span className={clsx(miniLabelClasses, { [miniLabelErrorClasses]: !noValidate && hoursErrors.length })}>
          hours
        </span>
        <span
          className={clsx(miniLabelClasses, {
            [miniLabelErrorClasses]: !noValidate && monthDaysErrors.length,
          })}
        >
          month days
        </span>
        <span className={clsx(miniLabelClasses, { [miniLabelErrorClasses]: !noValidate && monthsErrors.length })}>
          months
        </span>
        <span className={clsx(miniLabelClasses, { [miniLabelErrorClasses]: !noValidate && weekDaysErrors.length })}>
          week days
        </span>
        <IndividualInput
          id={id}
          order={1}
          className={clsx('col-start-1 row-start-2')}
          errors={noValidate ? [] : secondsErrors}
          value={seconds}
          onChange={(e) => setSeconds(e.target.value)}
        />
        <IndividualInput
          id={id}
          order={2}
          className={clsx('col-start-2 row-start-2')}
          errors={noValidate ? [] : minutesErrors}
          value={minutes}
          onChange={(e) => setMinutes(e.target.value)}
          required={!noValidate}
        />
        <IndividualInput
          id={id}
          order={3}
          className={clsx('col-start-3 row-start-2')}
          errors={noValidate ? [] : hoursErrors}
          value={hours}
          onChange={(e) => setHours(e.target.value)}
          required={!noValidate}
        />
        <IndividualInput
          id={id}
          order={4}
          className={clsx('col-start-4 row-start-2')}
          errors={noValidate ? [] : monthDaysErrors}
          value={monthDays}
          onChange={(e) => setMonthDays(e.target.value)}
          required={!noValidate}
        />
        <IndividualInput
          id={id}
          order={5}
          className={clsx('col-start-5 row-start-2')}
          errors={noValidate ? [] : monthsErrors}
          value={months}
          onChange={(e) => setMonths(e.target.value)}
          required={!noValidate}
        />
        <IndividualInput
          id={id}
          order={6}
          className={clsx('col-start-6 row-start-2')}
          errors={noValidate ? [] : weekDaysErrors}
          value={weekDays}
          onChange={(e) => setWeekdays(e.target.value)}
          required={!noValidate}
        />
        {!noCopy && (
          <button
            onClick={() =>
              copyText(`${seconds ? `${seconds} ` : ''}${minutes} ${hours} ${monthDays} ${months} ${weekDays}`).then(
                async () => {
                  setCopied(true)
                  await sleep(2500)
                  setCopied(false)
                }
              )
            }
            className="peer col-start-7 row-start-2 ml-1 flex max-w-fit items-center rounded border border-primary-300 px-2 text-gray-400 outline-none hover:border-primary-600 hover:text-primary-600 focus:border-primary-500 focus:text-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 hover:enabled:bg-primary-50 dark:hover:enabled:bg-primary-900"
          >
            {copied ? <CheckIcon className="h-5 w-5 text-green-500" /> : <ClipboardIcon className="h-5 w-5" />}
          </button>
        )}
        <div
          className={clsx('col-span-6 col-start-1 row-start-2 self-stretch rounded border text-primary-900', {
            'border-primary-300 group-focus-within:border-primary-500  group-focus-within:ring-1 group-focus-within:ring-primary-500 dark:group-focus-within:border-primary-500 dark:group-focus-within:ring-primary-500':
              noCopy,
            'border-primary-300 group-focus-within:peer-[:not(:focus)]:border-primary-500  group-focus-within:peer-[:not(:focus)]:ring-1 group-focus-within:peer-[:not(:focus)]:ring-primary-500 dark:group-focus-within:peer-[:not(:focus)]:border-primary-500 dark:group-focus-within:peer-[:not(:focus)]:ring-primary-500':
              !noCopy,
          })}
        />
      </div>
      {!noErrorMessage && allErrors.length > 0 && (
        <p className="mt-1 inline-block min-w-full max-w-min text-center text-xs text-red-300">
          {allErrors.join('; ')}
        </p>
      )}
      {!noFriendlyText && (
        <p className="mt-1 inline-block min-w-full max-w-min text-center text-sm text-gray-700 dark:text-gray-200">
          {friendlyText}
        </p>
      )}
    </div>
  )
}

interface IndividualInputProps extends ComponentProps<'input'> {
  order: number
  errors?: string[]
}

const IndividualInput = ({ id: containerId, order, className, errors, ...props }: IndividualInputProps) => {
  const id = `${containerId}-${order}`

  return (
    <div
      className={clsx(className, 'relative border-y border-transparent', {
        'rounded-l border-l': order === 1,
        'rounded-r border-r': order === 6,
      })}
    >
      <span className="invisible -z-10 block h-full whitespace-nowrap px-0.5 py-2 font-mono">{props.value || 'w'}</span>
      <input
        id={id}
        className={clsx(
          'absolute inset-0 w-full border-none bg-primary-100 bg-opacity-0 px-0.5 py-2 text-center font-mono outline-none transition-all duration-500 focus:bg-opacity-25 focus:outline-none dark:bg-gray-800 dark:text-white',
          {
            'rounded-l': order === 1,
            'rounded-r': order === 6,
            'bg-red-200 bg-opacity-25 dark:bg-red-400 dark:bg-opacity-25':
              errors.length || (props.required && !props.value),
          }
        )}
        {...props}
        onKeyDown={(e) => {
          const start = e.currentTarget.selectionStart
          const end = e.currentTarget.selectionEnd
          const collapsed = start === end
          const isAtBeginning = collapsed && start === 0
          const isAtEnd = collapsed && end === e.currentTarget.value?.length

          switch (e.key) {
            case 'Backspace':
            case 'ArrowLeft':
              if (isAtBeginning && order > 1) {
                e.preventDefault()
                const prevInput = document.getElementById(`${containerId}-${order - 1}`) as any
                if (prevInput) {
                  prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length)
                  prevInput.focus()
                }
              }
              return
            case 'Delete':
            case 'ArrowRight':
            case ' ':
              if (isAtEnd && order < 6) {
                e.preventDefault()
                const nextInput = document.getElementById(`${containerId}-${order + 1}`) as any
                if (nextInput) {
                  nextInput.setSelectionRange(0, 0)
                  nextInput.focus()
                }
              }
              return
          }
        }}
      />
    </div>
  )
}
