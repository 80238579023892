import { useMemo } from 'react'
import { parseCronParts, getFriendlyText, validatorGenerator } from './utils'

const zeroFiftyNine = Array(60)
  .fill(0)
  .map((_, i) => i.toString())
const zeroTwentyThree = Array(24)
  .fill(0)
  .map((_, i) => i.toString())
const zeroSix = Array(7)
  .fill(0)
  .map((_, i) => i.toString())
const oneThirtyOne = Array(31)
  .fill(1)
  .map((n, i) => (i + n).toString())
const oneTwelve = Array(12)
  .fill(1)
  .map((n, i) => (i + n).toString())
const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

const secondsValidator = validatorGenerator([zeroFiftyNine])
const minutesValidator = validatorGenerator([zeroFiftyNine])
const hoursValidator = validatorGenerator([zeroTwentyThree])
const monthDaysValidator = validatorGenerator([oneThirtyOne])
const monthsValidator = validatorGenerator([oneTwelve, months])
const weekDaysValidator = validatorGenerator([zeroSix, weekdays])

export const useCronStringState = (value: string, onChange: (newVal: string) => void) => {
  return useMemo(() => {
    const friendlyText = getFriendlyText(value)
    const { seconds, minutes, hours, monthDays, months, weekDays } = parseCronParts(value)

    const secondsErrors = secondsValidator(seconds)
    const minutesErrors = minutesValidator(minutes)
    const hoursErrors = hoursValidator(hours)
    const monthDaysErrors = monthDaysValidator(monthDays)
    const monthsErrors = monthsValidator(months)
    const weekDaysErrors = weekDaysValidator(weekDays)

    const change = (place: number) => (newVal: string) => {
      const editableCron = [seconds, minutes, hours, monthDays, months, weekDays]
      editableCron[place] = newVal.trim()

      onChange(editableCron.join(' '))
    }

    return {
      seconds,
      setSeconds: change(0),
      secondsErrors,
      minutes,
      setMinutes: change(1),
      minutesErrors,
      hours,
      setHours: change(2),
      hoursErrors,
      monthDays,
      setMonthDays: change(3),
      monthDaysErrors,
      months,
      setMonths: change(4),
      monthsErrors,
      weekDays,
      setWeekdays: change(5),
      weekDaysErrors,
      friendlyText,
    }
  }, [value])
}
