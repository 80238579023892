import clsx from 'clsx'
import React, { ComponentProps, ReactNode, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { CronInput } from './CronInput'

interface MonoCheckboxProps extends Omit<ComponentProps<'input'>, 'type'> {
  label?: ReactNode
}

const MonoCheckbox = ({ label = '', className = '', ...props }: MonoCheckboxProps) => {
  return (
    <label className={clsx('flex items-center font-mono text-gray-900', className)}>
      <input className="mr-2" type="checkbox" {...props} />
      {label}
    </label>
  )
}

const Example = () => {
  const [noCopy, setNoCopy] = useState(false)
  const [noFriendlyText, setNoFriendlyText] = useState(false)
  const [noValidate, setNoValidate] = useState(false)
  const [noErrorMessage, setNoErrorMessage] = useState(false)
  const [hideHelp, setHideHelp] = useState(false)
  const [cron, setCron] = useState('* * * * *')
  const [valid, setValid] = useState(true)

  return (
    <main className="flex h-screen flex-col items-center p-4 dark:bg-gray-900 dark:text-white">
      <h3 className="flex-center mb-8 flex-col">
        <span className="text-2xl text-primary-700">&lt;CronInput /&gt;</span>
        <span className="text-sm italic text-gray-400">from 8th Day Dev</span>
      </h3>
      <h3 className="mb-2 text-lg font-semibold text-gray-500">Props Controls</h3>
      <div className="mb-2 flex flex-wrap">
        <MonoCheckbox className="mr-4" checked={noCopy} onChange={(e) => setNoCopy(e.target.checked)} label="noCopy" />
        <MonoCheckbox
          className="mr-4"
          checked={noFriendlyText}
          onChange={(e) => setNoFriendlyText(e.target.checked)}
          label="noFriendlyText"
        />
        <MonoCheckbox
          className="mr-4"
          checked={noErrorMessage}
          onChange={(e) => setNoErrorMessage(e.target.checked)}
          label="noErrorMessage"
        />
        <MonoCheckbox
          className="mr-4"
          checked={noValidate}
          onChange={(e) => setNoValidate(e.target.checked)}
          label="noValidate"
        />
        <MonoCheckbox
          className="mr-4"
          checked={hideHelp}
          onChange={(e) => setHideHelp(e.target.checked)}
          label="hideHelp"
        />
      </div>
      <h3 className="mb-2 text-lg font-semibold text-gray-500">Values emitted</h3>
      <div className="flex flex-wrap">
        <div>
          <span className="mr-1 font-light text-gray-500">value/onChange:</span>
          <span className="font-bold text-gray-800">{cron}</span>
        </div>
        <div className="ml-8">
          <span className="mr-1 font-light text-gray-500">onValidityChange:</span>
          <span className="font-bold text-gray-800">{valid.toString()}</span>
        </div>
      </div>
      <hr className="my-8 w-full" />
      <CronInput
        label="My Cron Expression"
        value={cron}
        onChange={(c) => setCron(c)}
        noCopy={noCopy}
        noFriendlyText={noFriendlyText}
        noErrorMessage={noErrorMessage}
        noValidate={noValidate}
        onValidityChange={setValid}
        hideHelp={hideHelp}
      />
    </main>
  )
}

const root = createRoot(document.getElementById('app')!)

root.render(<Example />)
