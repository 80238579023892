import cronstrue from 'cronstrue'

export const parseCronParts = (cron: string) => {
  const parts = cron.split(' ')

  const [seconds, minutes, hours, monthDays, months, weekDays] = parts.length < 6 ? [''].concat(parts) : parts

  return {
    seconds,
    minutes,
    hours,
    monthDays,
    months,
    weekDays,
  }
}

export const copyText = async (text: string) => {
  try {
    return navigator.clipboard.writeText(text)
  } catch (e) {
    const currentActiveElement = document.activeElement as HTMLElement
    const ta = document.createElement('textarea')
    ta.style.position = 'fixed'
    ta.style.top = '-9999px'
    ta.style.left = '-9999px'
    ta.style.opacity = '0'

    document.body.append(ta)

    ta.value = text
    ta.focus()
    ta.select()

    try {
      document.execCommand('copy')
      document.body.removeChild(ta)
      currentActiveElement.focus()
    } catch (err) {
      console.error('Sorry, unable to copy on this browser')
      throw new Error('This browser does not allow copying right now')
    }
  }
}

export const getFriendlyText = (cron: string) => {
  try {
    return cronstrue.toString(cron)
  } catch (e) {
    return 'Not valid cron syntax'
  }
}

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms))

const digitsOnlyRegex = /^\d+$/

export const validatorGenerator =
  (validValueRanges: string[][]) =>
  (value: string = '') => {
    const values = value.split(',')

    const validator = (pureV: string) => {
      const v = removeLeadingZeros(pureV.toLowerCase())

      // dangling commas not cool
      if (values.length > 1 && v === '') {
        return 'dangling comma'
      }

      // accept the wildcard by itself or emtpy (will be validated by required fields)
      if (v === '*' || v === '') {
        return null
      }

      // is it a step?
      if (v.includes('/')) {
        // looking for numbers here, and start needs to be validated in its original state, so use pureV instead of v
        const [start, stepStr, wrong] = pureV.split('/')

        if (stepStr === '') {
          return 'dangling "/"'
        }

        if (wrong !== undefined) {
          return 'too many "/"s'
        }

        // validate first value
        const badStart = validator(start)
        if (badStart) {
          return badStart
        }

        // evaluate second value as being within the possible range length
        if (!stepStr.match(digitsOnlyRegex)) {
          return `invalid step: ${pureV.split('/')[1]} of ${pureV}`
        }

        return null
      }

      // is it a range?
      if (v.includes('-')) {
        const [first, second, wrong] = v.split('-')

        if (wrong === '') {
          return 'dangling hyphen'
        }

        if (wrong) {
          return 'too many hyphens'
        }

        // find index of first
        const firstIndex = findIndexAmongLists(validValueRanges, first)

        if (firstIndex === -1) {
          return `invalid value: ${pureV.split('-')[0]} of ${pureV}`
        }

        // search from index for second
        const secondIndex = findIndexAmongLists(validValueRanges, second, firstIndex)

        if (secondIndex === -1) {
          // if not found, search backward from index for better error message
          const retry = findIndexAmongLists(validValueRanges, second)

          if (retry === -1) {
            return `invalid value: ${pureV.split('-')[1]} of ${pureV}`
          }

          return `inverted range: ${pureV}`
        }

        return null
      }

      // is it a normal, valid value?
      if (validValueRanges.some((range) => range.some((validV) => validV === v))) {
        return null
      }

      return `invalid value: ${pureV}`
    }

    const errors = values.map(validator).filter(Boolean)

    return errors
  }

function removeLeadingZeros(value: string) {
  return value.replace(/(^|-|\/)0+(?=\d)/g, (_zeros, capture) => {
    return capture || ''
  })
}

function findIndexAmongLists(lists: string[][], value: string, startingIndex = 0) {
  const length = lengthOfLists(lists)

  for (let i = startingIndex; i < length; i++) {
    const containingList = lists.find((l) => l[i] === value)

    if (containingList) {
      return i
    }
  }

  return -1
}

function lengthOfLists(lists: any[][]) {
  return Math.max(...lists.map((l) => l.length))
}
